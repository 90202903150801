
import { defineComponent, onMounted, ref, Ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { useAuthStore } from "@/store/AuthStore"
import useAlert from "@/composables/Alert"
import logo from "@/assets/logo/logo-indicar-original-azul-horizontal 1.png"
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from "@/store/CompanyStore";
import { version } from "@/../package.json";
import axios, { AxiosError } from "axios";

export default defineComponent({
  name: "login",

  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    const loaderStore = useLoaderStore();
    const linkSuporte = ref("https://camptecnologia.com.br/");
    const linkIOS = ref("https://camptecnologia.com.br/");
    const linkAndroid = ref("https://camptecnologia.com.br/");
    const fullscreenLoading = ref(false);
    const router = useRouter();
    const formFields:Ref<{
      cpf: string,
      password: string
    }> = ref({
      cpf: "",
      password: ""
    })
    const timer = ref(0);
    const companyStore = useCompanyStore()
    const isBtnPasswordSMSVisible = ref(true)
    const appVersion = version;



    const authStore = useAuthStore()
    const { showTimeAlert } = useAlert()
    const userEmail = ref("")
    const userPhone = ref("")
  
    // Variables for form view control
    const showUserForm = ref(true)
    const showPassForm = ref(false)
    const showCommitmentTerm = ref(false)
    const userConfig = ref()

    function backStep1() {
      showUserForm.value = true
      showPassForm.value = false
      userEmail.value = ""
      userPhone.value = ""
      isBtnPasswordSMSVisible.value = true
    }

    // Step 1 - Validate User
    const chekcUserField = Yup.object().shape({
      cpf: Yup
        .string()
        .min(14, "Informe um CPF válido")
        .max(14)
        .required("Informe seu CPF")
        .label("CPF"),
    });

    const refSubmitBtnStep1 = ref<HTMLElement | null>(null)

    const startTimer = (timerSecond: number) => {
      timer.value = timerSecond
      const interval = setInterval(() => {
        timer.value--;

        if (timer.value < 1) {
          clearInterval(interval);
          timer.value = 0
        }
      }, 1000);
    };

    async function sendPasswordEmailSecondTime() {
      isBtnPasswordSMSVisible.value= false
      startTimer(process.env.NODE_ENV == "development" ? 10 : 59)
      try {
        const response = await authStore.sendPasswordEmail(formFields.value.cpf)
        if(response.data.data.email) {
          userEmail.value = response.data.data.email
          userPhone.value = ""
        }
        showTimeAlert("Verifique a caixa de mensagem do seu email!")
      } catch (error) {
          showTimeAlert("Algo deu errado tente enviar a senha por SMS", "error")
      } finally {
        isBtnPasswordSMSVisible.value= true
      }
    }

    async function sendPasswordSMS() {
      isBtnPasswordSMSVisible.value= false
      try {
        if(refSubmitBtnResendStep2.value) {
          refSubmitBtnResendStep2.value.setAttribute("data-kt-indicator", "on");
          refSubmitBtnResendStep2.value.setAttribute("disabled", "disabled");
        }
        const response = await authStore.sendPasswordSms(formFields.value.cpf)
        if(response.data.data.telephone) {
          userEmail.value = ""
          userPhone.value = response.data.data.telephone
        }
        if(refSubmitBtnResendStep2.value) {
          refSubmitBtnResendStep2.value.setAttribute("data-kt-indicator", "off");
          refSubmitBtnResendStep2.value.setAttribute("disabled", "enabled");
        }
        startTimer(process.env.NODE_ENV == "development" ? 10 : 30)
        showTimeAlert("Verifique a caixa de mensagem do seu telefone!")
      } catch (error) {
        showTimeAlert("Algo deu errado tente enviar a senha por email", "error")
      } finally {
        isBtnPasswordSMSVisible.value= true
      }
    }

    const insertWord = ref("")
    async function onSubmitUser(values) {
      if(refSubmitBtnStep1.value) {
        refSubmitBtnStep1.value.setAttribute("data-kt-indicator", "on");
        refSubmitBtnStep1.value.setAttribute("disabled", "disabled");
      }

      try {
        formFields.value.cpf = values.cpf.replaceAll('.', '').replace('-', '')
        const response: any = await authStore.sendPasswordEmail(formFields.value.cpf)
        if(response.data.data.email) {
          userEmail.value = response.data.data.email
        }
        if(process.env.NODE_ENV == "development") {
          if(response.data.data.codigo) {
            insertWord.value = `${response.data.data.codigo}`
          }
        }
        showTimeAlert("Verifique a caixa de mensagem do seu email!")
        showUserForm.value = false
        showPassForm.value = true
        startTimer(process.env.NODE_ENV == "development" ? 10 : 59)
      } catch (error) {
        showTimeAlert("Falha no processo de autenticação!", "error")
      }

      refSubmitBtnStep1.value!.removeAttribute("data-kt-indicator");
      refSubmitBtnStep1.value!.removeAttribute("disabled");
    }

    // Step 2 - Logging in
    const chekcPassField = Yup.object().shape({
      password: Yup
      .string()
      .required("Informe senha").label("Senha"),
    });

    const refSubmitBtnStep2 = ref<HTMLElement | null>(null)
    const refSubmitBtnResendStep2 = ref<HTMLElement | null>(null)

    const checkBoxRemember = ref(true);

    // async function onSubmitPass(values) {
    //   loaderStore.open()
    //   if (refSubmitBtnStep2.value) {
    //     refSubmitBtnStep2.value.setAttribute("data-kt-indicator", "on");
    //     refSubmitBtnStep2.value.setAttribute("disabled", "disabled");
    //   }
    //   try {
    //     formFields.value.password = values.password
    //     const response: any = await authStore.authentication(formFields.value.cpf, formFields.value.password)
    //     if (response.status === 200) {
    //       showTimeAlert("Sucesso na autenticação!")
    //       // showUserForm.value = true
    //       // showPassForm.value = false
    //       userConfig.value = response.data.data
    //       if (response.data.data.hasAcceptedTerm == false) {
    //         showCommitmentTerm.value = true
    //         loaderStore.close()
    //       } else {
    //         if (response.data.data.user.role.level <= 100 || response.data.data.company?.length > 1) {
    //           router.push({ name: "FilterCompanySession" })
    //         }
    //         else if (response.data.data.company) {
    //           // companyStore.setId(response.data.data.company[0]?.id)
    //           if (response.data.data.user.role.level < 600)
    //             router.push({ name: "FilterCompanySession" })
    //           else
    //             router.push({ name: "FilterCompanySession" })
    //         } else {
    //           showTimeAlert('Usuario não possui vinculo com companhia!', "error")
    //           authStore.logoutUser()
    //           loaderStore.close()
    //         }
    //       }
    //     } else {
    //       loaderStore.close()
    //       showTimeAlert('Credenciais inválidas!', "error")
    //     }
    //   } catch (error) {
    //     loaderStore.close()
    //     showTimeAlert('Falha no processo de autenticação!', "error")
    //   }
    //   refSubmitBtnStep2.value!.removeAttribute("data-kt-indicator");
    //   refSubmitBtnStep2.value!.removeAttribute("disabled");
    // }

    async function onSubmitPass(values) {
      loaderStore.open()
      if (refSubmitBtnStep2.value) {
        refSubmitBtnStep2.value.setAttribute("data-kt-indicator", "on");
        refSubmitBtnStep2.value.setAttribute("disabled", "disabled");
      }
      try {
        formFields.value.password = values.password
        const response: any = await authStore.authentication(formFields.value.cpf, formFields.value.password)
        if (response.status === 200) {
          showTimeAlert("Sucesso na autenticação!")
          if (response.data.data.user.role.level <= 100 || response.data.data.company?.length > 1) {
            router.push({ name: "FilterCompanySession" })
          } else if (response.data.data.company) {
            // companyStore.setId(response.data.data.company[0]?.id)
            if (response.data.data.user.role.level < 600)
              router.push({ name: "FilterCompanySession" })
            else
              router.push({ name: "FilterCompanySession" })
          } else {
            showTimeAlert('Usuario não possui vinculo com companhia!', "error")
            authStore.logoutUser()
            loaderStore.close()
          }
        } else {
          loaderStore.close()
          showTimeAlert('Credenciais inválidas!', "error")
        }
      } catch (error) {
        loaderStore.close()
        showTimeAlert('Falha no processo de autenticação!', "error")
      }
      refSubmitBtnStep2.value!.removeAttribute("data-kt-indicator");
      refSubmitBtnStep2.value!.removeAttribute("disabled");
    }

    async function postCommitmentTermPainel() {
      try {
        await axios.post("/api/postCommitmentTerm")
        if (userConfig.value.user.role.level <= 100 || userConfig.value.company?.length > 1) {
          router.push({ name: "FilterCompanySession" })
        }
        else if (userConfig.value.company) {
          // companyStore.setId(userConfig.value.company[0]?.id)
          if (userConfig.value.user.role.level < 600)
            router.push({ name: "FilterCompanySession" })
          else
            router.push({ name: "FilterCompanySession" })
        } else {
          showTimeAlert('Usuario não possui vinculo com companhia!', "error")
          authStore.logoutUser()
          loaderStore.close()
        }
      } catch (error) {
        throw error
      }
    }

    onMounted(() => {
      loaderStore.close()
    })

    return {
      /** default */
      fullscreenLoading,
      linkSuporte,
      linkAndroid,
      linkIOS,
      backStep1,
      /** step 1 */
      userEmail,
      showUserForm,
      chekcUserField,
      refSubmitBtnStep1,
      onSubmitUser,
      insertWord,
      /** step 2 */
      showPassForm,
      onSubmitPass,
      chekcPassField,
      refSubmitBtnStep2,
      refSubmitBtnResendStep2,
      isBtnPasswordSMSVisible,
      checkBoxRemember,
      logo,
      timer,
      sendPasswordEmailSecondTime,
      sendPasswordSMS,
      userPhone,
      appVersion,
      showCommitmentTerm,
      postCommitmentTermPainel
    };
  },
});
